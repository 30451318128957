import React from 'react'
import classes from "./PublicationStatus.module.css"

const PublicationStatus = ({ statusData }) => {

    const getPublicationStatusColors = (statusTranslated) => {
        const publicationsStyleMap = {
            "Rechazado": { color: "#FF6059", backgroundColor: "rgba(255, 96, 89, 0.2)" },
            "Pendiente": { color: "#57C2BD", backgroundColor: "rgba(87, 194, 189, 0.2)" },
            "Publicado": { color: "#22BB3D", backgroundColor: "rgba(34, 187, 61, 0.2)" }
        }

        return publicationsStyleMap[statusTranslated] || { styles: { color: "black", backGroundColor: "white" } }
    }

  return (
    <div className={classes.cardWrapper}>
        <span className={classes.textTitleDescription}>Estado de la publicación</span>
        <div className={classes.badgeWrapper} style={ getPublicationStatusColors(statusData.translatedStatus) }>
            <span>{ statusData.translatedStatus }</span>
        </div>
    </div>
  )
}


export default PublicationStatus