var config = {};
//  config.apiKey ='3pTvuFxcs79dzls8IFteY5JWySgfvswL9DgqUyP8'
config.apiKey = process.env.REACT_APP_APIKEY
config.apiKeyPim = process.env.REACT_APP_APIKEY_PIM
config.apiKeyQueues = process.env.REACT_APP_APIKEY_QUEUES
config.apiKeyAnalytics = process.env.REACT_APP_APIKEY_ANALYTICS
config.ambiente = process.env.REACT_APP_ENV
config.ambientePim = process.env.REACT_APP_ENV_PIM
config.ambienteQueues = process.env.REACT_APP_ENV_QUEUES
config.ambienteAnalytics = process.env.REACT_APP_ENV_ANALYTICS
config.bearerToken = process.env.REACT_APP_BEARERTOKEN
config.clientIdMeli = process.env.REACT_APP_CLIENT_ID_MELI
config.clientSecretMeli = process.env.REACT_APP_CLIENT_SECRET_MELI
config.webSocketConnectURL = process.env.REACT_APP_WEBSOCKET_CONNECT_URL
module.exports.config = config;