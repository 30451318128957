import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2'
import WarningModal from '../../../components/PublicationAlertModals/WarningModal';
import FirstAlertModal from '../../../components/PublicationAlertModals/FirstAlertModal';
import SecondAlertModal from '../../../components/PublicationAlertModals/SecondAlertModal';
import ThirdAlertModal from '../../../components/PublicationAlertModals/ThirdAlertModal';
import AddEmailModal from '../../../components/PublicationAlertModals/AddEmailModal';
import FourthAlertModal from '../../../components/PublicationAlertModals/FourthAlertModal';
import confirmation from '../../../assets/img/onboarding/Validacion.png'

//Services
import { getAlertTypes, postAlert } from 'services/alerts';

const CreateAlertModal = ({ warningModal, setWarningModal, firstModal, setFirstModal, toggleCreateAlertModal, setGlobalError, children, filter, clientIds, checkedPublications, selectAllPagesChecked, deselectedIds, setAlertCreationResult, setAlertTypes, setAlertCreationResultText }) => {

    const [alertData, setAlertData] = useState([]);
    const [secondModal, setSecondModal] = useState(false);
    const [thirdModal, setThirdModal] = useState(false);
    const [addEmailModal, setAddEmailModal] = useState(false);
    const [fourthModal, setFourthModal] = useState(false);    
    const [loadingAlertTypes, setLoadingAlertTypes] = useState(true);
    const [checkedAlertTypes, setCheckedAlertTypes] = useState([]);
    const [activeDays, setActiveDays] = useState({});
    const [selectedDate, setSelectedDate] = useState(null);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [emails, setEmails] = useState([localStorage.getItem("name")]);
    const [inputEmail, setInputEmail] = useState("");    
    const [checkedEmails, setCheckedEmails] = useState([]); 
    const [isCheckedTerms, setIsCheckedTerms] = useState(false);
    const [inputAlertName, setInputAlertName] = useState("");
    const [newAlertName, setNewAlertName] = useState(""); 
    const [hasDuplicateNameError, setHasDuplicateNameError] = useState(false);
    const [loadingAlert, setLoadingAlert] = useState(false);
    const [firstClick, setFirstClick] = useState(true);

    const days = [
        { key: 1, label: 'Lun', fullLabel: 'Lunes' },
        { key: 2, label: 'Mar', fullLabel: 'Martes' },
        { key: 3, label: 'Mie', fullLabel: 'Miércoles' },
        { key: 4, label: 'Jue', fullLabel: 'Jueves' },
        { key: 5, label: 'Vie', fullLabel: 'Viernes' },
    ];

    const handleDateChange = (start, end) => {
        setStartDate(start);
        setEndDate(end);
    };

    const handleAddEmail = (emailAddress) => {
        setEmails([...emails, emailAddress]);
        handleAddEmailModal();
    };

    const handleAlertName = (alertName) => {
        setLoadingAlert(true);
        setNewAlertName(alertName);
        handleCompleteAlert();
    };

    const handleCompleteAlert = () => {
        const publications = !selectAllPagesChecked ? checkedPublications : [];
        const noPublications = selectAllPagesChecked ? deselectedIds : [];
        let currentFilter = '';
        
        if (selectAllPagesChecked) {
            currentFilter = filter === '?' ? `client_id=${clientIds.join(',')}` : filter;
        }
    
        const formatDate = date => date ? date.toISOString().slice(0, 10) : '';
    
        const selectedDays = Object.keys(activeDays)
            .filter(key => activeDays[key])
            .map(key => {
                const day = days.find(day => day.key.toString() === key);
                return day ? day.key : '';
            });
    
        const parseFilterString = (filterString) => {
            const queryString = filterString.startsWith('?') ? filterString.slice(1) : filterString;
            const queryParams = new URLSearchParams(queryString);
            const filterObject = {};
            const keyMapping = {
                store_id: 'client_id',
                country_id: 'country.id',
                category_name: 'category',
                brand_name: 'brand'
            };
    
            for (const [key, value] of queryParams.entries()) {
                const mappedKey = keyMapping[key] || key;
                if (mappedKey === 'client_id') {
                    filterObject[mappedKey] = { $in: value.split(',').map(Number) };
                } else if (mappedKey === 'country.id') {
                    filterObject[mappedKey] = isNaN(value) ? value : Number(value);
                } else {
                    if (value.includes(',')) {
                        filterObject[mappedKey] = value.split(',').map(Number);
                    } else {
                        filterObject[mappedKey] = isNaN(value) ? value : Number(value);
                    }
                }
            }
            return filterObject;
        };
    
        const finalAlertData = {
            alert: {
                alert_name: inputAlertName,
                alert_type_ids: checkedAlertTypes,
                alert_frequency: selectedDays,
                alert_start_date: formatDate(startDate),
                alert_end_date: formatDate(endDate),
                alert_recipients: checkedEmails,
                alert_creator: localStorage.getItem("name"),
                alert_creation_date: new Date().toISOString(),
                enabled: true
            },
            filter: parseFilterString(currentFilter),
            publications: publications,
            no_publications: noPublications
        };
    
        handlePostAlert(finalAlertData);
    };
    
    const toggleDatePicker = () => {
        setShowDatePicker(prev => !prev);
    };

    const toggleDay = (day) => {
        if(!startDate){
            setStartDate(new Date());
        }        
        setActiveDays((prev) => ({ ...prev, [day]: !prev[day] }));
    };    

    const toggleWarningModal = () => setWarningModal(!warningModal);
    const toggleFirstModal = () => setFirstModal(!firstModal);
    const toggleSecondModal = () => setSecondModal(!secondModal);
    const toggleThirdModal = () => setThirdModal(!thirdModal);
    const toggleAddEmailModal = () => setAddEmailModal(!addEmailModal);
    const toggleFourthModal = () => setFourthModal(!fourthModal);

    const handleCloseModals = () => {
        setCheckedAlertTypes([]);
        setActiveDays({});
        setSelectedDate(null);
        setShowDatePicker(false);
        setStartDate(null);
        setEndDate(null);
        setIsButtonEnabled(false);  
        setEmails(null);   
        setEmails([localStorage.getItem("name")]);
        setInputEmail(""); 
        setCheckedEmails([]);
        setIsCheckedTerms(false);
        setNewAlertName("");
        setInputAlertName("");
    };

    const getAlertTypesData = async () => {
        try {
            setLoadingAlertTypes(true);
            const response = await getAlertTypes();
            setAlertData(response.data);
            setAlertTypes(response.data)
        } catch (error) {
            setGlobalError(error.message);
        } finally {
            setLoadingAlertTypes(false);
        }
    };

    const handlePostAlert = async (finalAlertData) => {
        let correctResponse = false;
        let response = null;
        try {           
            response = await postAlert(finalAlertData);            
            
            if (response.data.data.has_duplicate_name) {
                setHasDuplicateNameError(true);                
            } else {
                setHasDuplicateNameError(false);
            }
            
            correctResponse = response?.data?.data?.code !== 500 ;

        } catch (error) {
            setGlobalError(error.message);
            setHasDuplicateNameError(false);
        } finally {
            setLoadingAlert(false)
            if(correctResponse && !response.data.data.has_duplicate_name){
                setAlertCreationResultText("¡Eureka! Creaste la alerta con éxito");
                setAlertCreationResult(true);                
                handleCloseFourthModalOnSuccess();
            }else if(!correctResponse && !response.data.data.has_duplicate_name){
                setAlertCreationResultText("Error en el cambio... ¡Intentalo nuevamente!"); 
                setAlertCreationResult(false);
            }
        }
    };

    const handleFirstModal = () => {
        toggleWarningModal();
        toggleFirstModal();
    };

    const handleSecondModal = () => {        
        toggleFirstModal();
        toggleSecondModal();
    };

    const handleThirdModal = () => {
        toggleSecondModal();
        toggleThirdModal();
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }; 

    const handleAddEmailModal = () => {
        if (isValidEmail(inputEmail)) {
            setInputEmail(null); 
            toggleThirdModal();
            toggleAddEmailModal();
        }
    };

    const handleAddEmailModalReturn = () => {
        toggleThirdModal();
        toggleAddEmailModal();
    };

    const handleFourthModal = () => {
        toggleThirdModal();
        toggleFourthModal();
    };

    const handleButtonClick = () => {
        toggleSecondModal();
        toggleThirdModal();
    };

    const handleCloseFirstModal = async () => {        
        if(checkedAlertTypes.length > 0){
            const modalResult = await closeConfirmationModal() 
            if(modalResult && checkedAlertTypes.length > 0){
                handleCloseModals();
                toggleFirstModal();
            } 
        }else{
            handleCloseModals();
            toggleFirstModal();
        }    
                 
    };

    const handleCloseSecondModal = async () => {
        const modalResult = await closeConfirmationModal()        
        if(modalResult){
            handleCloseModals();
            toggleSecondModal();
        }         
    };

    const handleCloseThirdModal = async () => {
        const modalResult = await closeConfirmationModal()        
        if(modalResult){
            handleCloseModals();
            toggleThirdModal();
        }         
    };

    const handleCloseAddEmailModal = async () => {
        const modalResult = await closeConfirmationModal()        
        if(modalResult){            
            handleCloseModals();
            toggleAddEmailModal();  
        }               
    };

    const handleCloseFourthModal = async () => {
        const modalResult = await closeConfirmationModal()        
        if(modalResult){
            handleCloseModals();
            toggleFourthModal();
        }         
    };

    const handleCloseFourthModalOnSuccess = async () => {
        handleCloseModals();
        toggleFourthModal();       
    };

    useEffect(() => {
        const activeDaysCount = Object.values(activeDays).filter(Boolean).length;
        setIsButtonEnabled(activeDaysCount > 0);
        if(activeDaysCount < 1 && firstClick && !endDate){
            setStartDate(null);
        }
    }, [activeDays, startDate]);

    const formatDate = (date, monthFormat = 'long') => {
        const options = { day: 'numeric', month: monthFormat, year: 'numeric' };
        return new Intl.DateTimeFormat('es-ES', options).format(date);
    };
    
    const formatDateRange = (start, end) => {
        if (start && end) {
            const sameMonthYear = start.getMonth() === end.getMonth() && start.getFullYear() === end.getFullYear();
            const sameYear = start.getFullYear() === end.getFullYear();
    
            if (sameMonthYear) {
                const endDateFormatted = formatDate(end, 'long');
                return `${start.getDate()} - ${endDateFormatted}`;
            } else if (sameYear) {
                const startDateFormatted = formatDate(start, 'long');
                const endDateDayMonth = new Intl.DateTimeFormat('es-ES', { day: 'numeric', month: 'long' }).format(end);
                return `${startDateFormatted} - ${endDateDayMonth} de ${end.getFullYear()}`;
            } else {
                const startDateFormatted = formatDate(start, 'long');
                const endDateFormatted = formatDate(end, 'long');
                return `${startDateFormatted} - ${endDateFormatted}`;
            }
        } else if (start) {
            return formatDate(start, 'long');
        }
        return "Selecciona una fecha";
    };
    
    const handleInputEmailChange = (event) => {
        setInputEmail(event.target.value);
    };

    const handleInputAlertNameChange = (event) => {
        setInputAlertName(event.target.value);    
        setHasDuplicateNameError(false); 
    };        

    useEffect(() => {
        if (firstModal && loadingAlertTypes) {
            getAlertTypesData();
        }
    }, [firstModal, loadingAlertTypes]);


    const closeConfirmationModal = async () => {
        return new Promise(function (resolve) {
          Swal.fire({
            title: '¿ Seguro que...',
            html: "quieres salir sin nada de <br> lo que avanzaste se guarde?",
            imageWidth: 150,
            imageHeight: 150,
            imageUrl: confirmation,
            confirmButtonText: 'Continuar',
            showDenyButton: true,
            denyButtonText: `Salir sin guardar`,
            customClass: {
              actions: 'action-close-modal',
            },
            allowOutsideClick: false
    
          }).then(async (result) => {
            resolve(!result.isConfirmed)
          })
        })
    }

    return (
        <div>
            {warningModal && (
                <WarningModal
                    warningModal={warningModal}
                    toggleWarningModal={toggleWarningModal}
                    handleFirstModal={handleFirstModal}
                />
            )}

            {firstModal && (
                <FirstAlertModal
                    firstModal={firstModal}
                    toggleFirstModal={toggleFirstModal}
                    checkedAlertTypes={checkedAlertTypes}
                    loadingAlertTypes={loadingAlertTypes}
                    handleCloseFirstModal={handleCloseFirstModal}
                    alertData={alertData}
                    setCheckedAlertTypes={setCheckedAlertTypes}
                    handleSecondModal={handleSecondModal}
                />
            )}


            {secondModal && (
                <SecondAlertModal
                    secondModal={secondModal}
                    toggleSecondModal={toggleSecondModal}
                    isButtonEnabled={isButtonEnabled}
                    handleSecondModal={handleSecondModal}
                    handleCloseSecondModal={handleCloseSecondModal}
                    days={days}
                    toggleDay={toggleDay}
                    activeDays={activeDays}
                    toggleDatePicker={toggleDatePicker}
                    startDate={startDate}
                    endDate={endDate}
                    formatDateRange={formatDateRange}
                    formatDate={formatDate}
                    showDatePicker={showDatePicker}
                    selectedDate={selectedDate} 
                    handleDateChange={handleDateChange} 
                    setStartDate={setStartDate} 
                    setEndDate={setEndDate}
                    handleButtonClick={handleButtonClick}
                    firstClick={firstClick}
                    setFirstClick={setFirstClick}
                />
            )}

            {thirdModal && (
                <ThirdAlertModal
                    thirdModal={thirdModal}
                    toggleThirdModal={toggleThirdModal}                
                    handleThirdModal={handleThirdModal}
                    handleCloseThirdModal={handleCloseThirdModal}
                    handleAddEmailModalReturn={handleAddEmailModalReturn}                
                    setCheckedEmails={setCheckedEmails}
                    setIsCheckedTerms={setIsCheckedTerms}
                    handleFourthModal={handleFourthModal}
                    emails={emails}
                    checkedEmails={checkedEmails}
                    isCheckedTerms={isCheckedTerms}
                />
            )}

            {addEmailModal && (
                <AddEmailModal
                    addEmailModal={addEmailModal}
                    toggleAddEmailModal={toggleAddEmailModal}
                    handleAddEmailModalReturn={handleAddEmailModalReturn}
                    handleCloseAddEmailModal={handleCloseAddEmailModal}
                    inputEmail={inputEmail}
                    handleInputEmailChange={handleInputEmailChange}
                    handleAddEmail={handleAddEmail}
                />
            )}

            {fourthModal && (
                <FourthAlertModal
                    fourthModal={fourthModal}
                    toggleFourthModal={toggleFourthModal}
                    inputCreationAlertName={inputAlertName}
                    loadingAlert={loadingAlert}
                    handleFourthModal={handleFourthModal}
                    handleCloseFourthModal={handleCloseFourthModal}
                    hasDuplicateNameError={hasDuplicateNameError}
                    handleInputCreationAlertNameChange={handleInputAlertNameChange}
                    handleAlertName={handleAlertName}
                    newAlertName={newAlertName}
                />
            )}
        </div>
    );
};

export default CreateAlertModal;
