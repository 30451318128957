import {performanceMetric} from "../utils/utils";

const configFile = require('../config/url');

export const authUser = async (email, password) => {
    const accessToken = localStorage.getItem('Token');
    const bearerToken = `Bearer ${accessToken}`;
    const myHeaders = new Headers()
    myHeaders.append('x-api-key', configFile.config.apiKey);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
        'Authorization',
        `${bearerToken}`
    )
    const raw = JSON.stringify({
        email: email,
        password: password,
      })
    const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
    }
    try {
        const start = Date.now();
        const response = await fetch(`${configFile.config.ambiente}login`, requestOptions);
        performanceMetric("/login", "POST", "mtd", localStorage.getItem("name"), Date.now()-start, accessToken);
        const data = await response.json()
        return data.message
    }
    catch(err) {
        return err
    }     
}

export const forgetUser = async (email) => {
    const myHeaders = new Headers()
    myHeaders.append('x-api-key', configFile.config.apiKey);
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
    method: "PUT",
    headers: myHeaders,
    redirect: "follow",
    }
    try {
        const start = Date.now();
        const response = await fetch(`${configFile.config.ambiente}user/forget?user=${email}`, requestOptions)
        performanceMetric("/user/forget", "PUT", "mtd", localStorage.getItem("name"), Date.now()-start, accessToken);

        if (response.ok) {
            return "Success"
        } else {
            throw new Error("Server error")
        }
    }
    catch(err) {
        return err
    }     
}

export const changePassword = async (password) => {
    const accessToken = localStorage.getItem('TokenTemporary');
    const userEmail = localStorage.getItem('UserTemporary');
    const bearerToken = `Bearer ${accessToken}`;
    const myHeaders = new Headers()
    myHeaders.append('x-api-key', configFile.config.apiKey);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
        'Authorization',
        `${bearerToken}`
        )
    const userBody = JSON.stringify({
        email: userEmail,
        password: password,
    })
    const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: userBody,
        redirect: "follow",
    }
    try {
        const start = Date.now();
        const response = await fetch(`${configFile.config.ambiente}user?user=${userEmail}`, requestOptions)
        performanceMetric("/user", "PUT", "mtd", localStorage.getItem("name"), Date.now()-start, accessToken);
        if (response.ok) {
            return "Success"
        } else {
            throw new Error("Server error")
        }
    }
    catch(err) {
        return err
    }     
}