import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../../components/UI/CheckboxAlert';
import questionMark from "../../../assets/img/icons/questionMark.svg";
import alertTypeCard from './AlertTypeCard.module.css';
import AlertTooltip from './UI/AlertTooltip';
import { useMediaQuery } from '@mui/material';

const AlertTypeCard = ({ alertType, checked = false, checkedAlertTypes, setCheckedAlertTypes }) => {
    const [isChecked, setIsChecked] = useState(checked);
    const isMobile = useMediaQuery('(max-width:600px)');
    useEffect(() => {
        setIsChecked(checkedAlertTypes.includes(alertType.typeId));
    }, [checkedAlertTypes, alertType.typeId]);

    const handleCheckboxChange = () => {
        const newCheckedStatus = !isChecked;
        setIsChecked(newCheckedStatus);

        if (newCheckedStatus) {
            setCheckedAlertTypes([...checkedAlertTypes, alertType.typeId]);
        } else {
            setCheckedAlertTypes(checkedAlertTypes.filter(id => id !== alertType.typeId));
        }
    };

    const isDisabled = alertType.enabled !== true;

    return (
        <div className={`${alertTypeCard.cardWrapper} ${isChecked ? alertTypeCard.checkedCard : ''} ${isDisabled ? alertTypeCard.disabledCard : ''}`}>
            <Checkbox
                checked={isChecked}
                onChange={handleCheckboxChange}
                disabled={isDisabled}
            />
            <div className={alertTypeCard.cardTitleContainer}>
                <span 
                    className={alertTypeCard.cardTitle} 
                    onClick={!isDisabled ? handleCheckboxChange : undefined}
                    style={{ cursor: !isDisabled ? 'pointer' : 'default', marginLeft:isMobile ? "15px":"" }}
                >
                    {alertType.name}
                </span>
            </div>
            <div className={alertTypeCard.groupIcon}>
                <AlertTooltip tooltipContent={alertType.description}>
                    <img src={questionMark} alt="Question Mark Icon" />
                </AlertTooltip>
            </div>
        </div>
    );
};

AlertTypeCard.propTypes = {
    alertType: PropTypes.shape({
        typeId: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
        enabled: PropTypes.bool.isRequired,
    }).isRequired,
    checked: PropTypes.bool,
    checkedAlertTypes: PropTypes.arrayOf(PropTypes.number).isRequired,
    setCheckedAlertTypes: PropTypes.func.isRequired,
};

export default AlertTypeCard;
