import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";
import { useMediaQuery, Box } from "@mui/material";
import classes from "./EditClientProfile.module.css";
import WelcomeClientEdit from "components/WelcomeClientEdit/welcome-client-edit";
import DisplayClientStores from "./components/displayEditClientStores/displayEditClientStores";
import OnboardingView from "views/EditClientProfile/components/OnboardingClientView/OnboardingClientView";
import EditDocumentTributario from "components/EditDocumentTributario/edit-document-tributario";
import PersonalizeClientStore from "components/PersonalizeClientStore/personalize-client-store";
import AdminSalesChannels from "./components/AdminSalesChannels/AdminSalesChannels";
import AdminBackoffice from "components/AdminBackoffice/AdminBackoffice";
import AdminFulfillment from "components/AdminFulfillment/admin-fulfillment";
import SideBar from "./components/SideBar/SideBar";
import SideBarClientSelected from "./components/SideBarClientSelected/SideBarClientSelected";
import SplashScreen from "components/UI/splash-screen";
import Swal from 'sweetalert2'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { ArrowUpward } from '@mui/icons-material';
//services
import { getStoreDetails } from "services/stores";
import { getRefreshTokenMeli } from "services/channelsIntegration";
import { createChannel, generateTokensByOauth } from "services/channels";

//images
import felicidades from '../../assets/img/onboarding/felicidades.png'
import Error from '../../assets/img/onboarding/Error.png'
import lineExpand from '../../assets/img/icons/lineExpand.png'
import Close from "../../assets/img/icons/close_black.png"

const ClientProfile = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [storeName, setstoreName] = useState("");
  const [storeId, setstoreId] = useState("");
  const [storeDetail, setstoreDetail] = useState([]);
  const [companyData, setcompanyData] = useState("");
  const [storeData, setStoreData] = useState({});
  const [userEmail, setUserEmail] = useState();
  const [isLoading, setIsLoading] = useState(true)

  const [displayer, setDisplayer] = useState(
    {
      displayEditDocument: false,
      displayWelcome: false,
      displayStores: false,
      displayBackoffice: false,
      personalizeStore: false,
      onboardingView: false,
      channelsView: false,
      fulfillmentView: false,
    }
  )

  useEffect(async () => {

    const searchParams = new URLSearchParams(window.location.search)
    const code = searchParams.get('code')
    const store = searchParams.get('storeId')
    const onboarding = searchParams.get('onboarding')

    const state = searchParams.get('state')
    const sellingPartnerId = searchParams.get('selling_partner_id')
    const spAPIOauthCode = searchParams.get('spapi_oauth_code')

    if (code) {
      await processCodeMeli(code)
    }
    else if (state && sellingPartnerId && spAPIOauthCode) {
      await processAmazonCode(state, sellingPartnerId, spAPIOauthCode)
    }
    else if (store)
      redirectChannelAdmin(store, onboarding)
    else {
      displaySelector("displayWelcome")
      setIsLoading(false)
    }

    setUserEmail(localStorage.getItem("name"))
  }, [])

  const showIntegrationSuccessMiniModal = (idstore) => {
    Swal.fire({
      title: 'Felicidades!',
      text: `El canal fue integrado correctamente`,
      imageWidth: 100,
      imageHeight: 150,
      imageUrl: felicidades,
      closeButtonHtml: `<img src="${Close}" />`
    }).then(async () => {
      await redirectChannelAdmin(idstore)
    })
  }

  const showErrorMiniModal = async (idStore, shouldRedirectToChannelAdmin = true) => {
    Swal.fire({
      title: 'Error..',
      text: `Ocurrio un error al integrar el canal`,
      imageWidth: 100,
      imageHeight: 150,
      showCloseButton: true,
      imageUrl: Error,
      closeButtonHtml: `<img src="${Close}" />`
    }).then(async () => {
      if (shouldRedirectToChannelAdmin) {
        await redirectChannelAdmin(idStore)
      } else {
        displaySelector("displayWelcome")
      }
    })
  }

  const processCodeMeli = async (code) => {
    try {
      const idstore = localStorage.getItem("storeIntegration")
      const countryId = localStorage.getItem("countryIntegration")
      let tokenData = await getRefreshTokenMeli(code);

      if (tokenData?.status === 400) {
        showErrorMiniModal(idstore)
      } else {

        //funcion que crea cliente en channels 
        let storeIntegrationResult = await createChannel(idstore, [
          {
            "channel": 2,
            "paises": [parseInt(countryId)],
            "urls": {
              "url": "",
              "refresh_token": tokenData.refresh_token
            }
          }
        ])

        if (storeIntegrationResult) {
          showIntegrationSuccessMiniModal(idstore)
          localStorage.removeItem("storeIntegration")
          localStorage.removeItem("countryIntegration")
        } else {
          showErrorMiniModal(idstore)
        }
      }

    } catch (error) {
      showErrorMiniModal(null, false)
      setIsLoading(false)
    }
  }

  const processAmazonCode = async (stateData, sellingPartnerId, spAPIOauthCode) => {
    const stateDataSplitted = stateData.split("-")
    const storeId = stateDataSplitted[0]
    const countryId = stateDataSplitted[1]

    const responseTokens = await generateTokensByOauth({
      channelId: 18,
      grantType: "code",
      code: spAPIOauthCode
    })

    if (responseTokens.error) {
      showErrorMiniModal(storeId)
    }
    else {
      let storeIntegrationResult = await createChannel(storeId, [
        {
          channel: 18,
          paises: [parseInt(countryId)],
          urls: {
            refresh_token: responseTokens.response?.data?.message?.refresh_token,
            seller_id: sellingPartnerId
          }
        }
      ])

      const resultFound = storeIntegrationResult?.results?.find(resultNewChannel => resultNewChannel?.channelId == 18)
      if (resultFound?.result === "Success") {
        showIntegrationSuccessMiniModal(storeId)
      }
      else {
        showErrorMiniModal(storeId)
      }
    }
  }

  const redirectChannelAdmin = async (id, onboarding) => {
    let storeResponse = await fetchStoreDetails(id)
    getStoreDetail(storeResponse)
    if (onboarding) {
      displaySelector("onboardingView")
    } else {
      displaySelector("channelsView")
    }

    window.history.replaceState({}, document.title, window.location.origin + '/admin/clientProfile')
    setIsLoading(false)
  }

  const displaySelector = (button) => {
    let displayerAux = {};

    for (const key in displayer) {
      if (key == button) {
        displayerAux[key] = true;
      } else {
        displayerAux[key] = false;
      }
    }

    setDisplayer(displayerAux);
  }

  const getStoreDetail = (storeDetail) => {

    setstoreId(storeDetail.id);
    setstoreName(storeDetail.name);
    setstoreDetail(storeDetail);
  };

  const fetchStoreDetails = async (id) => {
    const response = await getStoreDetails(id);

    try {
      setStoreData(response.message)
      return response.message
    }
    catch (err) {
      console.log("error", err);
    }
  };

  return (
    <>
      {isLoading && <SplashScreen />}

      <div className={classes.wrapper}>
        {!isMobile ? <h5 className={classes.titleTable}>
          Perfil de usuario
        </h5> : <></>}

        <p className={classes.textNameTable}>

        </p>
        <div className={classes.mainGrid}>
          {
            (!displayer["onboardingView"] && !displayer["channelsView"] && !displayer["fulfillmentView"]) &&
            <>
              {!isMobile ?
                <SideBar
                  displayWelcomeEditHandler={() => { displaySelector("displayWelcome") }}
                  displayStoreHandler={() => { displaySelector("displayStores") }}
                  displayer={displayer}
                ></SideBar>
                :
                <div style={{ position: 'fixed', right: 0, bottom: 0, width: '100%', zIndex: 2, alignItems: "center", justifyContent: "center" }}>
                  <Accordion
                    defaultExpanded={true}
                  >
                    <AccordionSummary
                      sx={{
                        backgroundColor: "#FBFBFB",
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        pb: 2.5

                      }}
                      expandIcon={<ArrowUpward sx={{ background: "#DBD9D9", borderRadius: "50%" }} />}
                      aria-controls="panel-content"
                      id="panel-header"
                    >
                    </AccordionSummary>
                    <AccordionDetails >

                      <SideBar
                        displayWelcomeEditHandler={() => { displaySelector("displayWelcome") }}
                        displayStoreHandler={() => { displaySelector("displayStores") }}
                        displayer={displayer}
                      ></SideBar>

                    </AccordionDetails>
                  </Accordion>
                </div>

              }
            </>

          }
          {
            (displayer["onboardingView"] || displayer["channelsView"] || displayer["fulfillmentView"]) &&
            <>
              {!isMobile ? <SideBarClientSelected
                displayWelcomeEditHandler={() => { displaySelector("displayWelcome") }}
                displayFulfillmentView={() => { displaySelector("fulfillmentView") }}
                displayOnboardingView={() => { displaySelector("onboardingView") }}
                displayChannelsView={() => { displaySelector("channelsView") }}
                displayer={displayer}
              ></SideBarClientSelected>
                :
                <div style={{ position: 'fixed', bottom: 0, right: 0, width: '100%', zIndex: 2 }}>
                  <Accordion
                    defaultExpanded={true}
                  >
                    <AccordionSummary
                      sx={{
                        backgroundColor: "#FBFBFB",
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        pb: 2.5

                      }}
                      expandIcon={<ArrowUpward sx={{ background: "#DBD9D9", borderRadius: "50%" }} />}
                      aria-controls="panel-content"
                      id="panel-header"
                    >
                    </AccordionSummary>
                    <AccordionDetails >

                      <SideBarClientSelected
                        displayWelcomeEditHandler={() => { displaySelector("displayWelcome") }}
                        displayFulfillmentView={() => { displaySelector("fulfillmentView") }}
                        displayOnboardingView={() => { displaySelector("onboardingView") }}
                        displayChannelsView={() => { displaySelector("channelsView") }}
                        displayer={displayer}
                        isMobile={true}
                      />
                    </AccordionDetails>
                  </Accordion>
                </div>
              }

            </>

          }

          <div className={!isMobile ? classes.colRight : ""}>
            {!isMobile ?
              <Card style={{ backgroundColor: "#FBFBFB" }} className={classes.welcomeCard}>
                <ViewsClient
                  displayer={displayer}
                  displaySelector={displaySelector}
                  getStoreDetail={getStoreDetail}
                  fetchStoreDetails={fetchStoreDetails}
                  companyData={companyData}
                  storeId={storeId}
                  storeDetail={storeDetail}
                  storeData={storeData}
                  userEmail={userEmail}
                  setStoreData={setStoreData}
                />
              </Card> :
              <Box sx={{ pt: 2 }}>
                <ViewsClient
                  displayer={displayer}
                  displaySelector={displaySelector}
                  getStoreDetail={getStoreDetail}
                  fetchStoreDetails={fetchStoreDetails}
                  companyData={companyData}
                  storeId={storeId}
                  storeDetail={storeDetail}
                  storeData={storeData}
                  userEmail={userEmail}
                  setStoreData={setStoreData}
                />
              </Box>

            }

          </div>
        </div>
      </div>
    </>
  );
};

const ViewsClient = ({ displayer, displaySelector, getStoreDetail, fetchStoreDetails, companyData, storeId, storeDetail, storeData, userEmail, setStoreData }) => {
  return (
    <>
      <div id="welcomeTextContainer">
        {
          displayer["displayWelcome"] &&
          <WelcomeClientEdit />
        }
      </div>
      <div id="editClientStore">
        {
          displayer["displayStores"] && (
            <DisplayClientStores
              displayStoreDetailHandler={() => { displaySelector("onboardingView") }}
              getStoreDetail={getStoreDetail}
            />
          )
        }
      </div>
      <div id="editClientStore">
        {
          displayer["onboardingView"] && (
            <OnboardingView
              storeData={storeData}
              clientDetails={storeDetail}
              backToStores={() => { displaySelector("displayStores") }}
              fetchStoreDetails={fetchStoreDetails}
            ></OnboardingView>
          )
        }
      </div>
      <div id="editClientStore">
        {
          displayer["displayEditDocument"] && (
            <h1>
              <EditDocumentTributario
                companyData={companyData}
                displayStoreHandler={() => { displaySelector("onboardingView") }}
                id={storeId}
                storeName={storeName}
              ></EditDocumentTributario>
            </h1>
          )
        }
      </div>
      <div id="editClientStore">
        {
          displayer["personalizeStore"] && (
            <PersonalizeClientStore
              companyData={companyData}
              storeName={storeName}
              id={storeId}
              displayStoreHandler={() => { displaySelector("onboardingView") }}
            />
          )
        }
      </div>
      {
        displayer["channelsView"] &&
        <AdminSalesChannels
          storeData={storeData}
          fetchStoreDetails={fetchStoreDetails}
          userEmail={userEmail}
          backToStores={() => { displaySelector("displayStores") }}
          setStoreData={setStoreData}
        />
      }
      {
        displayer["displayBackoffice"] &&
        <AdminBackoffice
          storeData={storeData}
          backToStoreDetail={() => { displaySelector("onboardingView") }}
          fetchStoreDetails={fetchStoreDetails}
        />
      }
      <div>
        {
          displayer["fulfillmentView"] &&
          <AdminFulfillment
            storeData={storeData}
            backToStoreDetail={() => { displaySelector("onboardingView") }}
            fetchStoreDetails={fetchStoreDetails}
          />
        }
      </div>
    </>
  )
}


export default ClientProfile;
