import React, { useState, useEffect, useRef, useCallback } from 'react'

import moment from 'moment'

import { Skeleton } from '@mui/material';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';

import classes from './DownloadsCenter.module.css'

import AlertTooltip from '../../views/publications/Components/UI/AlertTooltip';

import websocketHelper from "../../utils/websockets"

import unfoldToRigthIcon from "../../assets/img/icons/unfold-to-right.svg"
import unfoldToLeftIcon from "../../assets/img/icons/unfold-to-left.svg"
import inforFortooltipIcon from "../..//assets/img/icons/info-tooltip.svg"
import downloadEnabledIcon from "../..//assets/img/icons/download-enabled.svg"
import downloadDisabledIcon from "../..//assets/img/icons/download-disabled.svg"

import { getDocumentDownloads } from 'services/catalog'

const DownloadsCenter = ({ setActionsForDownloads, actionsForDownloads }) => {

    const [isHidden, setisHidden] = useState(true)
    const [isLoadingDocuments, setIsLoadingDocuments] = useState(true)
    const [documentsDownloads, setDocumentDownloads] = useState([])
    const [showAlert, setshowAlert] = useState(false)

    const handleClickUnfold = () =>{
        setisHidden((prevValue) => !prevValue)
    }

    const getAllDocumentDownloads = async () => {
        setIsLoadingDocuments(true)
        const response = await getDocumentDownloads()
        setIsLoadingDocuments(false)
        if (!response.error) {
            setDocumentDownloads(response?.data?.data || [])
        }
    }

    const downloadExcelFile = (fileURL) => {
        if(fileURL)
          window.open(fileURL)
      }

    const parseDocumentType = (type) => {
        const typesMap = {
            "catalog": "Catálogo PIM",
            "publications": "Publicaciones"
        }

        return typesMap[type] || type
    }

     /* websocket */
     const socket = useRef(null)

     const onSocketMessage = useCallback((data) => {
 
         if(data){
             if(data.messageType === "reportIsReady"){
                console.log("reportIsReady")
                setshowAlert(true)
                getAllDocumentDownloads()
                setisHidden(false)
             }
         }
     }, [])
 
     const connectWebsocket = useCallback(() => {
 
        websocketHelper.connect()
            .then(websocketInstance => {
                socket.current = websocketInstance
 
                socket.current?.onMessage?.addListener(onSocketMessage);
 
            })
            .catch(e => {
                console.error(e)
            })
     }, [])

    useEffect(() => {
        getAllDocumentDownloads()
        connectWebsocket()

        return () => {
            websocketHelper.disconnect(socket?.current)
            socket.current?.onMessage?.removeListener(onSocketMessage);
        }
      }, [])

    useEffect(() => {
        if(actionsForDownloads.refreshDownloads){
            getAllDocumentDownloads()
            setActionsForDownloads({
                ...actionsForDownloads,
                refreshDownloads: false
            })
        }

        if(actionsForDownloads.unfoldDownloadCenter){
            setisHidden(false)
        }
    }, [actionsForDownloads])

    useEffect(() => {
        if (showAlert) {
          const timeoutId = setTimeout(() => {
            setshowAlert(false);
          }, 5000);
    
          return () => clearTimeout(timeoutId);
        }
      }, [showAlert]);

    
    return (
        <>
        {showAlert ?
            <Alert
            icon={false}
            sx={{
                zIndex: 10000,
                fontFamily: 'Montserrat',
                borderRadius: "10px !important",
                color: "#22BB3D",
                backgroundColor: "#E9F9EC",
                minHeight: "65px",
                paddingTop: "10px",
                minWidth: {
                    xs: "310px",
                    md: "420px",
                    lg: "540px"
                },
                fontSize: {
                    xs: "10px",
                    md: "14px",
                    lg: "18px"
                },
                position: "fixed",
                top: "30px",
                right: "0px"
            }}
            action={
                <CloseIcon
                sx={{
                    fontSize: "27px",
                    color: "#24BB3E",
                    cursor: "pointer",
                    marginTop: "4px"
                }}
                onClick={() => {
                    setshowAlert(false);
                }} />
            }
            >
            <b>Se ha terminado de generar una a más descargas</b>
            <div
                style={{
                    clipPath: 'polygon(17px 0px, 100% 0px, 98% 100%, 30% 100%, 50% 80%, 90% 100%, 23px 100%)',
                    position: 'absolute',
                    bottom: '0px',
                    left: -17,
                    right: 0,
                    height: '12%',
                    backgroundColor: '#24BB3E',
                }}
            />
            </Alert>
        :
            null
        }
        {
            !isHidden ? 
            (<div className={classes.DownloadCenterWrapper}>
                <div className={classes.DownloadRowsWrapper}>
                    {documentsDownloads?.length ?
                        documentsDownloads.map(item => (
                            <div className={classes.DownloadRow}>
                                <img
                                    className={classes.DownloadIconImg}
                                    alt="download-icon" 
                                    src={item?.status === "done" ? downloadEnabledIcon : downloadDisabledIcon}
                                    onClick={() => downloadExcelFile(item.url)}
                                    style={{ 
                                        cursor: item.status === "done" ? "pointer" : "not-allowed"
                                    }}
                                />
                                <div className={ [classes.RowTextWrapper, classes.DateTextWrapper].join(' ')}>
                                    {moment(item.created_at).format("DD-MM-YYYY HH:mm")}
                                </div>
                                <div className={ [classes.RowTextWrapper, classes.DownloadTypeTextWrapper].join(' ') }>
                                    {parseDocumentType(item.type)}
                                </div>
                            </div>
                        ))
                    :
                        <div className={classes.DownloadRow}>
                        {isLoadingDocuments ?
                            <div className={classes.LoaderWrapper}>
                                <Skeleton variant="rectangular" height={30} width={"30%"} sx={{ borderRadius: 2.5 }} style={{marginRight: 5, marginLeft: 15}}/>
                                <Skeleton variant="rectangular" height={30} width={"40%"} sx={{ borderRadius: 2.5 }} style={{marginRight: 5}}/>
                                <Skeleton variant="rectangular" height={30} width={"20%"} sx={{ borderRadius: 2.5 }} />
                            </div>
                        :
                            <div className={classes.NoDownloadsWrapper}>
                                <span >No existen descargas generadas</span>
                            </div>
                        }
                        </div>
                    }
                
                </div>
                <div className={classes.TittleWrapper}>
                    <div className={classes.TittleContainer} onClick={handleClickUnfold}>
                        <div className={classes.TittleText} >Central de descargas</div>
                    </div>
                    <AlertTooltip
                        tooltipContent={
                            <div className={classes.TooltipContentWrapper}>
                                <p className={classes.TopParagrpahTootltip}>
                                <span className={classes.TopSpanTooltip}>
                                    Esta central de descargas es para que puedas guardar tus últimas 5 descargas! <br />
                                    <br />
                                </span>
                                <span className={classes.BottomSpantooTtip}>Solicita las descargas que necesites y ven a buscar siempre al mismo lugar</span>
                                </p>
                            </div>
                        }
                        skipStringSanitization={true}
                    >
                        <img className={classes.infoIconForTootltip} alt="info" src={inforFortooltipIcon} />
                    </AlertTooltip>
                    <img className={classes.DownloadFileIcon} alt="Frame" src={unfoldToRigthIcon} onClick={handleClickUnfold}/>
                </div>
            </div>)
        : 
            (
                <div className={classes.unfoldDownloadCenterBtn} onClick={handleClickUnfold}>
                    <img className={classes.unfoldLeftIcon} alt="Frame" src={unfoldToRigthIcon} />
                </div>
            )
        }   
        </>
    )


}

export default DownloadsCenter