import React from 'react'
import EmptyPublciationsIcon from "../../../assets/icons/EmptyPublications.svg"
import classes from './EmptyPublication.module.css'

export default function EmptyPublications({ calledFrom="publicationsList" }) {
  return (
    <div className={calledFrom === "publicationsList" || calledFrom === "publicationCardHealthcheck" ? classes.container : classes.containerHealthcheckIcon}>
        <img
            className={calledFrom === "publicationsList" ? classes.emptyPublicationsIcon: classes.emptyHealthcheckIcon}
            src={EmptyPublciationsIcon}
            alt="empty-publications-icon"
        />
        <div className={classes.textContainer}>
            <span className={calledFrom === "publicationsList" ? classes.text : classes.textHealthcheck}>Vaya...</span>
            <span className={calledFrom === "publicationsList" ? classes.text : classes.textHealthcheck}>No hay información disponible</span>
        </div>
    </div>
    
  )
}
