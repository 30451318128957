import React, { useState } from 'react';
import { FormControl, Select, MenuItem } from '@mui/material';
import classes from './Pagination.module.css'
import { ChevronLeft, ChevronRight, ExpandMore } from "@material-ui/icons";
import arrowRightImg from '../../assets/img/arrow-right-for-select-2.png'
import arrowLeftImg from '../../assets/img/arrow-left-for-select-2.png'
import arrowSelect from '../../assets/img/Despliegue.png'

const CustomExpandMore2 = () => {
    return (
        <div className={classes.arrowDownWrapper}>
            <img src={arrowSelect} />
        </div>
    )
}

const Pagination = ({ total, isLoading, page, setPage, rowsPerPage, setRowsHandle, onChangeRowsPerPage, rowsPerPageOptions, name, useOldTypography=true, checkedCount=0, onSelectAll, selectAllPagesChecked }) => {

    const [rows, setRows] = useState(rowsPerPage);
    let totalPages = (Math.round(total / rowsPerPage)) === 0 ? 1 : (Math.round(total / rowsPerPage));

    const handleChange = (event) => {
        setRows(event.target.value);
        setRowsHandle(event.target.value);
        onChangeRowsPerPage(event.target.value);
        window.scrollTo(0, 0);
    };

    const formatTotal = (num) => {
        return new Intl.NumberFormat('es-ES').format(num);
    };

    return (
        
        <div className={classes.pagination}>
            <div className={classes.checkedCounter}>
                {checkedCount > 0 ? (
                    <div className={classes.checkedPublications}>
                        {formatTotal(checkedCount)} {checkedCount > 1 ? "selecciones" : "selección" } {!selectAllPagesChecked ? <span className={classes.selectAllPages} onClick={onSelectAll}>Seleccionar todas las otras páginas</span> : <span className={classes.selectAllPages} onClick={onSelectAll}>Revertir selección</span>}
                    </div>
                ) : (
                    <div className={classes.publications}>
                        {formatTotal(total)} publicaciones
                    </div>
                )} 
            </div>
            <div className={!checkedCount ? classes.pageSelection : classes.pageSelectionWhenChecked}>
                <div className={classes.selector + " selectPaginationWrapper"}>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} style={{ fontSize: "0.8rem" }}>
                        <Select
                            value={rows}
                            label="rows"
                            onChange={handleChange}
                            style={{ boxShadow: "none" }}
                            IconComponent={CustomExpandMore2}
                            className={classes.selectPageSize}
                        >
                            {rowsPerPageOptions.map((option) => (
                                <MenuItem key={option} value={option} className={classes.optionsText}> {option} {name} </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div 
                    className={classes.iconWrapper}
                    onClick={
                        (e) => {
                            if (page !== 1 && !isLoading) {
                                setPage(page - 1)
                            }
                        }
                    }
                >
                        <img src={arrowSelect} className={classes.icon} style={{ transform: "rotate(90deg)"}}/>
                </div>
                <span className={classes.text}> Página {page} de {totalPages}</span>

                <div 
                    className={classes.iconWrapper}
                    onClick={
                        (e) => {
                            if (!isLoading && rows * page < Math.round(total)) {
                                setPage(page + 1)
                            }
                        }
                    }
                >
                        <img src={arrowSelect} className={classes.icon} style={{ transform: "rotate(270deg)"}}/>
                </div>
            </div>       
        </div>
    );
}

export default Pagination