const configFile = require('../config/url');
import {performanceMetric} from "../utils/utils";

export const getDocument = async (idStore) => {
    const accessToken = localStorage.getItem('Token')
    const bearerToken = `Bearer ${accessToken}`
    const myHeaders = new Headers()
    myHeaders.append('x-api-key', configFile.config.apiKeyPim);
    myHeaders.append(
        'Authorization',
        `${bearerToken}`
    )
    const requestOptions = {
        method: "GET",
        headers: myHeaders,
    }
    try {
        const start = Date.now(); 
        const response = await fetch(`${configFile.config.ambientePim}documents?type=user_upload&alias=products_upload&store_id=${idStore}`, requestOptions)
        performanceMetric("/documents", "GET", "pim", localStorage.getItem("name"), Date.now()-start, accessToken);
        if (!response.ok){ 
            const data = await response.json()
            if (data.internal_code === "server_error") {
                throw new Error('Existe algun problema en el servidor')
            } else if (data.internal_code === "user_cant_get_document") {
                throw new Error('El usuario no tiene permisos para obtener el recurso')
            } else {
                throw  new Error('Algo salió mal. Inténtalo de nuevo más tarde.')
            }
        }
        if (response.ok) return await response.json()
    }
    catch(err) {
        return err
    }
}

export const getMaster = async (alias = "products_upload") => {
    const accessToken = localStorage.getItem('Token')
    const bearerToken = `Bearer ${accessToken}`
    let myHeaders = new Headers()
    myHeaders.append('x-api-key', configFile.config.apiKeyPim);
    myHeaders.append(
        'Authorization',
        `${bearerToken}`
    )
    const requestOptions = {
    method: "GET",
    redirect: 'follow',
    headers: myHeaders,
    };
    try {
        const start = Date.now(); 
        const response = await fetch(`${configFile.config.ambientePim}documents?type=template&alias=${alias}`, requestOptions)
        performanceMetric("/documents", "GET", "pim", localStorage.getItem("name"), Date.now()-start, accessToken);
        if (!response.ok){ 
            const data = await response.json()
            if (data.internal_code === "server_error") {
                throw new Error('Existe algun problema en el servidor')
            } else if (data.internal_code === "user_cant_get_document") {
                throw new Error('El usuario no tiene permisos para obtener el recurso')
            } else {
                throw  new Error('Algo salió mal. Inténtalo de nuevo más tarde.')
            }
        }
        if (response.ok) return await response.json()
    }
    catch(err) {
        return err
    }
}

export const postFile = async (formData) => {
    const accessToken = localStorage.getItem('Token')
    const bearerToken = `Bearer ${accessToken}`
    const myHeaders = new Headers()
    myHeaders.append('x-api-key', configFile.config.apiKeyPim);
    myHeaders.append(
        'Authorization',
        `${bearerToken}`
    )
    const requestOptions = {
        method: "POST",
        body: formData,
        headers: myHeaders,
    }
    try {
        const start = Date.now(); 
        const response = await fetch(`${configFile.config.ambientePim}products/sheet`, requestOptions)
        performanceMetric("/products/sheet", "POST", "pim", localStorage.getItem("name"), Date.now()-start, accessToken);
        if (!response.ok){ 
            const data = await response.json()
            if (data.internal_code === "file_size_limit"){
                throw new Error('El tamaño del archivo supera el máximo permitido.')
            } else if (data.internal_code === "server_error") {
                throw new Error('Existe un problema en el servidor. Inténtalo de nuevo más tarde.')
            } else if (data.internal_code === "invalid_file_type") {
                throw new Error('Formato de archivo no soportado. Prueba con archivos .jpeg')
            } else if (data.internal_code === "invalid_file_count_or_fields") {
                throw new Error('La cantidad de archivos supera el límite establecido.')
            } else if (data.internal_code === "file_no_products") {
                throw new Error('El archivo no tiene productos.')
            } else if (data.internal_code === "store_not_found") {
                throw new Error('No se encontró la tienda especificada.')
            } else if (data.internal_code === "require_field_empty") {
                throw new Error('Uno o más campos requeridos se encuentran vacíos. Revisa el archivo y rellena todos los campos.')
            } else if (data.internal_code === "field_type") {
                throw new Error('Uno de los campos en el archivo tiene un tipo de dato incorrecto.')
            } else if (data.internal_code === "file_parsing_error") {
                throw new Error('Error al parsear archivo.')
            } else if (data.internal_code === "error_finding_category") {
                throw new Error('Error mientras se buscaba una de las categorías. Revisa la lista de categorías en la maestra.')
            } else if (data.internal_code === "product_category_not_found") {
                throw new Error('No se encontró una o más categorías. Revisa la lista de categorías en la maestra.')
            } else if (data.internal_code === "error_uploading_to_cloud") {
                throw new Error('Error al intentar guardar el archivo.')
            } else if (data.internal_code === "error_saving_url") {
                throw new Error('Error al guardar la url del archivo en la base de datos.')
            } else if (data.internal_code === "empty_file") {
                throw new Error('Archivo vacío o no se encontró archivo en body.')
            } else if (data.internal_code === "bad_sheet_name") {
                throw new Error('El nombre de la hoja en los productos debe ser "CARGA DE PRODUCTOS".')
            } else if (data.internal_code === "error_parsing_file") {
                throw new Error('Error mientras se parseaba el archivo, puede ser por error en el formato o error interno.')
            } else if (data.internal_code === "no_body") {
                throw new Error('No hay body en request o está vacío.')
            } else if (data.internal_code === "incorrect_column_name") {
                throw new Error('Archivo de maestra incorrecto. Descarga la plantilla e inténtelo nuevamente')
            } else {
                throw new Error('Algo salió mal. Inténtalo de nuevo más tarde.')
            }
        }
        if (response.ok) return await response.json()
    }
    catch(err) {
       return err
    }
}

export const getUrl = async (body) => {
    const accessToken = localStorage.getItem('Token')
    const bearerToken = `Bearer ${accessToken}`
    const myHeaders = new Headers()
    myHeaders.append('x-api-key', configFile.config.apiKeyPim);
    myHeaders.append(
        'Authorization',
        `${bearerToken}`
    )
    myHeaders.append("Content-Type", "application/json");
    const rawBody = JSON.stringify(body);
    const requestOptions = {
        method: "POST",
        body: rawBody,
        headers: myHeaders,
        redirect: 'follow'
    }
    try {
        const start = Date.now(); 
        const response = await fetch(`${configFile.config.ambientePim}config/s3url`, requestOptions)
        performanceMetric("/config/s3url", "POST", "pim", localStorage.getItem("name"), Date.now()-start, accessToken);
        if (!response.ok){ 
            const data = await response.json()
            if (data.internal_code === "images_range_error"){
                throw new Error('La cantidad de imagenes esta fuera del rango establecido')
            } else if (data.internal_code === "no_body") {
                throw new Error('La solicitud esta vacia')
            } else if (data.internal_code === "server_error") {
                throw new Error('Existe algun problema en el servidor')
            } else if (data.internal_code === "data_must_be_array") {
                throw new Error('Data debe ser un array')
            } else {
                throw  new Error('Algo salió mal. Inténtalo de nuevo más tarde.')
            }
        }
        if (response.ok) return await response.json()
    } 
    catch(err) {
        return err
    }
}

export const postImage = async (url, file) => {
    const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data",
          "Cache-Control": "max-age=60",
        },
        body: file
    }
    try {
        const response = await fetch(`${url}`, requestOptions)
        return response
    } 
    catch(err) {
        return err
    }
}

export const saveImage = async (body) => {
    const accessToken = localStorage.getItem('Token')
    const bearerToken = `Bearer ${accessToken}`
    const myHeaders = new Headers()
    myHeaders.append('x-api-key', configFile.config.apiKeyPim);
    myHeaders.append(
        'Authorization',
        `${bearerToken}`
    )
    myHeaders.append("Content-Type", "application/json");
    const rawBody = JSON.stringify(body);
    const requestOptions = {
        method: "POST",
        body: rawBody,
        headers: myHeaders,
        redirect: 'follow'
    }
    try {
        const start = Date.now(); 
        const response = await fetch(`${configFile.config.ambientePim}products/images`, requestOptions)
        performanceMetric("/products/images", "POST", "pim", localStorage.getItem("name"), Date.now()-start, accessToken);
        if (!response.ok) {
            const data = await response.json()
            if (data.internal_code === "images_range_error"){
                throw new Error('La cantidad de imagenes esta fuera del rango establecido')
            } else if (data.internal_code === "no_body") {
                throw new Error('La solicitud esta vacia')
            } else if (data.internal_code === "server_error") {
                throw new Error('Existe algun problema en el servidor')
            } else if (data.internal_code === "data_must_be_array") {
                throw new Error('Data debe ser un array')
            } else {
                throw  new Error('Algo salió mal. Inténtalo de nuevo más tarde.')
            }
        }
        if (response.ok) return await response.json()
    } 
    catch(err) {
        return err
    }
}

export const getCatalog = async (stores) => {

    const accessToken = localStorage.getItem('Token')
    const bearerToken = `Bearer ${accessToken}`

    const myHeaders = new Headers()
    myHeaders.append('x-api-key', configFile.config.apiKeyPim)
    myHeaders.append('Authorization', `${bearerToken}`)
    myHeaders.append("Content-Type", "application/json")


    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    }

    try {
        const start = Date.now(); 
        const response = await fetch(`${configFile.config.ambientePim}products/catalog?store_id=${stores}&type=XLXS`, requestOptions)
        performanceMetric("/products/catalog", "GET", "pim", localStorage.getItem("name"), Date.now()-start, accessToken);
        if (!response.ok) {
            const data = await response.json()
            if (data.internal_code === "server_error") {
                console.log("error cfcararef")
                throw new Error('Existe algun problema en el servidor')
            } else {
                console.log("error cfcararef")
                throw new Error('Algo salió mal. Inténtalo de nuevo más tarde.')
            }
        }
        if (response.ok) return await response.json()
    }
    catch (err) {
        console.log("fawfeawfewa")
        return err
    }
}

export const getDocumentDownloads = async (queryString="") => {

    const accessToken = localStorage.getItem('Token')
    const bearerToken = `Bearer ${accessToken}`

    const myHeaders = new Headers()
    myHeaders.append('x-api-key', configFile.config.apiKeyPim)
    myHeaders.append('Authorization', `${bearerToken}`)
    myHeaders.append("Content-Type", "application/json")


    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    }

    try {
        const start = Date.now(); 
        const response = await fetch(`${configFile.config.ambientePim}documents_downloads${queryString}`, requestOptions)
        performanceMetric("/documents_downloads", "GET", "pim", localStorage.getItem("name"), Date.now()-start, accessToken);
        if (!response.ok) {
            return { 
                error: await response.json(),
                data: null
            }
        }
        if (response.ok) 
            return {
                data: await response.json(),
                error: null
            }
    }
    catch (err) {
       
        return {
            data: null,
            error: err
        }
    }
}