import React, { useEffect, useState } from 'react'
import classes from './Select.module.css'
import arrowSelect from '../../assets/img/icons/arrowSelect.svg'
import Input from 'components/Promotions/Input'

const Select = ({ handle, name, options, handleOptions, selectedOption, value = "value", search = false, parentOptions }) => {
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [openOptions, setOpenOptions] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [defaultOption, setDefaultOption] = useState('Seleccionar')

    useEffect(() => {
        setFilteredOptions(options)
    }, [options])

    useEffect(() => {
        setDefaultOption(options.length > 0 || parentOptions?.length > 0 ? 'Seleccionar' : 'Cargando filtros...')
    }, [parentOptions])

    const handleOpenOptions = () => setOpenOptions(prevState => !prevState)

    const handleSearch = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
        const filtered = options.filter((option) =>
            option[name].toLowerCase().includes(query.toLowerCase())
        );
        setFilteredOptions(filtered);
    };

    const sortByAtt = (a, b) => {
        if (a[name].toLowerCase() < b[name].toLowerCase())
            return -1;
        if (a[name].toLowerCase() > b[name].toLowerCase())
            return 1;
        return 0;
    }

    return (
        <div className={classes.wrapper}>
            <div onClick={handleOpenOptions} className={classes.inputSelect}>
                <div className={classes.mainText}>
                    {selectedOption ? selectedOption : defaultOption}
                </div>
                <div className={classes.iconWrapper}>
                    <img src={arrowSelect} className={classes.icon} />
                </div>
            </div>
            {openOptions &&
                <div className={classes.options}>
                    {search &&
                        <div className={classes.searchWrapper}>
                            <Input width="100%" type="text" value={searchQuery} handle={handleSearch} />
                        </div>
                    }
                    {filteredOptions.sort(sortByAtt).map((option) => (
                        <div
                            key={option[value]}
                            className={classes.option}
                            onClick={() => {
                                handle(option[value])
                                handleOptions(option[name])
                                handleOpenOptions()
                            }} >
                            {option[name]}
                        </div>
                    ))}
                </div>
            }
        </div>
    )
}




export default Select