import React, { useEffect } from 'react';
import { Alert, IconButton } from '@mui/material';
import closeAlert from '../../assets/img/icons/closeAlert.svg'
import closeAlertError from '../../assets/img/icons/closeAlertError.svg'

const typeAlerts = [
    { id: "success", color: "#E9F9EC", btColor:"#24BB3E", closeIcon: closeAlert },
    { id: "error", color: "#FDEBE9", btColor:"#EB3223", closeIcon: closeAlertError }
];

const AlertSeller = ({ showAlert, text, setShowAlert, time, type }) => {

    useEffect(() => {
        if (showAlert) {
            const timeoutId = setTimeout(() => {
                setShowAlert(false);
            }, time);

            return () => clearTimeout(timeoutId);
        }
    }, []);

    return (
        <Alert
            icon={false}
            sx={{
                zIndex: 1400,
                fontFamily: 'Montserrat',
                position: 'fixed',  
                top: 30,             
                right: 30,          
                borderRadius: "10px !important",
                color: typeAlerts.find(alert=>alert.id === type)?.btColor || "#24BB3E",
                backgroundColor: typeAlerts.find(alert=>alert.id === type)?.color || "#E9F9EC",
                minHeight: "97px",
                paddingTop: "10px",
                minWidth: "540px",
                fontSize: "20px",
                display: 'flex',        
                alignItems: 'center',   
                justifyContent: 'space-between'
            }}
            action={

                <IconButton
                    onClick={() => {
                        setShowAlert(false);
                    }}
                    sx={{
                        cursor: "pointer",
                        marginTop: "-7px",
                        '&:hover': {
                            backgroundColor: 'transparent'
                        }
                    }}>
                    <img src={typeAlerts.find(alert=>alert.id === type)?.closeIcon || closeAlert} alt="closeIcon"/>
                </IconButton>
            }
        >
            <b>{text}</b>
            <div
                style={{
                    clipPath: 'polygon(17px 0px, 100% 0px, 98% 100%, 30% 100%, 50% 80%, 90% 100%, 23px 100%)',
                    position: 'absolute',
                    bottom: '0px',
                    left: -17,
                    right: 0,
                    height: '12%',
                    backgroundColor: typeAlerts.find(alert=>alert.id === type)?.btColor || '#24BB3E',
                }}
            />
        </Alert>
    );
};

export default AlertSeller;
