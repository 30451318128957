import React, { useState, useEffect, useRef } from 'react';
import styles from './DatePicker.module.css';
import ArrowLeft from "../../assets/img/icons/arrow-left.svg";
import ArrowRight from "../../assets/img/icons/arrow-right.svg";

const months = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
];

const DatePicker = ({ selectedDate, onDateChange, onClose, startDate, endDate, setStartDate, setEndDate, firstClick, setFirstClick }) => {
    const [currentDate, setCurrentDate] = useState(selectedDate || new Date());    
    const [viewMode, setViewMode] = useState('month'); // 'month' or 'year'
    const [currentMonth, setCurrentMonth] = useState(false);
    const datePickerRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
                onClose(); // Call the onClose function passed from props
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

    const handleDateSelect = (date) => {
        if (firstClick) {
            setStartDate(date);
            setEndDate(null);
            setFirstClick(false);
        } else {
            if (startDate && date.getTime() === startDate.getTime()) {
                setStartDate(new Date());
                setEndDate(null);
                setFirstClick(true);
            } else {
                if (date >= startDate) {
                    setEndDate(date);
                } else {
                    setStartDate(date);
                    setEndDate(null);
                }
                setFirstClick(true);
            }
        }

        // Swap startDate and endDate if endDate is earlier than startDate
        if (startDate && endDate && endDate < startDate) {
            setStartDate(endDate);
            setEndDate(startDate);
        }
    };

    const daysInMonth = (year, month) => {
        return new Date(year, month + 1, 0).getDate();
    };

    const getMonthDays = (year, month) => {
        const firstDayIndex = (new Date(year, month, 1).getDay() + 6) % 7; // Adjusting so the week starts on Monday
        const days = [];

        // Add empty placeholders for days before the first day of the month
        for (let i = 0; i < firstDayIndex; i++) {
            days.push(null);
        }

        // Add days of the month
        for (let i = 1; i <= daysInMonth(year, month); i++) {
            days.push(new Date(year, month, i));
        }

        return days;
    };

    const formatMonthYear = (date) => {
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        
        if (viewMode === 'month') {
            return `${month} ${year}`;
        } else if (viewMode === 'year') {
            return `${year}`;
        }
    };

    const renderCalendar = () => {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();
        const days = getMonthDays(year, month);
    
        // Calculate start and end dates
        const startDateValue = startDate ? startDate.getTime() : null;
        const endDateValue = endDate ? endDate.getTime() : null;
        const today = new Date();
        const todayValue = new Date(today.getFullYear(), today.getMonth(), today.getDate()).getTime();
    
        // Helper function to check if a date is within the selected range
        const isInRange = (date) => {
            const dateValue = date.getTime();
            return startDateValue && endDateValue && dateValue > startDateValue && dateValue < endDateValue;
        };

        const isStartDate = (date) => startDate && date.getTime() === startDateValue;
        const isEndDate = (date) => endDate && date.getTime() === endDateValue;
    
        // Helper function to check if a month is currently active
        const isActiveMonth = (index) => {
            return viewMode === 'year' && currentDate.getMonth() === index;
        };
    
        if (viewMode === 'month') {
            return (
                <div className={styles.calendar}>
                    <div className={styles.calendarHeader}>
                        <button type="button" onClick={() => {
                            setCurrentDate(new Date(year, month - 1));
                        }} className={styles.leftArrowButton}>
                            <img src={ArrowLeft} alt="Previous Month" />
                        </button>
                        <span onClick={() => setViewMode('year')}>{formatMonthYear(currentDate)}</span>
                        <button type="button" onClick={() => {
                            setCurrentDate(new Date(year, month + 1));
                        }} className={styles.rightArrowButton}>
                            <img src={ArrowRight} alt="Next Month" />
                        </button>
                    </div>
                    <div className={styles.calendarBody}>
                        <div className={styles.daysOfWeek}>
                            {['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom'].map(day => (
                                <div key={day} className={styles.dayOfWeek}>{day}</div>
                            ))}
                        </div>
                        <div className={styles.daysGrid}>
                            {days.map((day, index) => (
                                <div key={index} className={`${styles.dayRow} ${day && isInRange(day) ? styles.inRangeDay : ''}`}>
                                    {day && (
                                        <div
                                            className={`${styles.day} ${isStartDate(day) ? styles.startDate : ''} ${isEndDate(day) ? styles.endDate : ''} ${day.getTime() === todayValue && !endDate && firstClick ? styles.currentDay : ''}`}
                                            onClick={() => day && handleDateSelect(day)}
                                        >
                                            {day.getDate()}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            );
        } else if (viewMode === 'year') {
            return (
                <div className={styles.calendar}>
                    <div className={styles.calendarHeader}>
                        <button type="button" onClick={() => {
                            setCurrentDate(new Date(year - 1, month));
                        }} className={styles.leftArrowButton}>
                            <img src={ArrowLeft} alt="Previous Year" />
                        </button>
                        <span onClick={() => setViewMode('month')}>{formatMonthYear(currentDate)}</span>
                        <button type="button" onClick={() => {
                            setCurrentDate(new Date(year + 1, month));
                        }} className={styles.rightArrowButton}>
                            <img src={ArrowRight} alt="Next Year" />
                        </button>
                    </div>
                    <div className={styles.calendarBody}>
                        <div className={styles.monthsGrid}>
                            {months.map((monthName, index) => (
                                <div
                                    key={index}
                                    className={`${styles.month} ${isActiveMonth(index) ? styles.active : ''}`}
                                    onClick={() => {
                                        setCurrentMonth(true);
                                        setCurrentDate(new Date(year, index));
                                    }}
                                >
                                    {monthName}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            );
        }
    };

    return (
        <div ref={datePickerRef} className={styles.datePicker}>
            {renderCalendar()}
            <div className={styles.datePickerFooter}>
                {viewMode === 'year' ? (
                    <span
                        className={styles.applyButton}
                        disabled={!currentMonth}
                        onClick={() => {
                            setCurrentMonth(false);
                            setViewMode('month');
                        }}
                    >
                        Aplicar
                    </span>
                ) : (
                    <span
                        className={styles.applyButton}
                        disabled={!startDate}
                        onClick={onClose}
                    >
                        Aplicar
                    </span>
                )}
            </div>
        </div>
    );
};

export default DatePicker;
