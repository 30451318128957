import React, { useState,  useEffect } from 'react'
import { Modal } from 'reactstrap'
import Tooltip from '@mui/material/Tooltip';
import classes from './PublicationModal.module.css'
import ProgressBar from './ProgressBar';
import PublicationStatus from "./PublicationStatus"

import { translateLogisticType } from "./../../../utils/utils"

//images
import Canasta from "../../../assets/img/canasta.png";
import Location from "../../../assets/img/icons/location.png";
import Question from "../../../assets/img/icons/question.png";
import WhiteCase from "../../../assets/img/whiteCase.png";
import GanandoIlu from "../../../assets/img/ganando.png";
import Ganando from "../../../assets/img/ganando 2.png";
import PerdiendoIlu from "../../../assets/img/perdiendo.png";
import Perdiendo from "../../../assets/img/Perdiendo 2.png";
import EmpatandoIlu from "../../../assets/img/Empatando.png";
import Empatando from "../../../assets/img/Empatando 2.png";
import check from "../../../assets/img/check.png"
import noCheck from "../../../assets/img/no-check.png"
import Close from "../../../assets/img/icons/close_black.png"
import EmptyPublications from './EmptyPublications';


const PublicationModal = ({ product, modalToggle, handleToggleModal }) => {
    const toggle = () => { handleToggleModal() };
    const [isMobileSizes, setIsMobileSized] = useState(false);

    useEffect(() => {
        // set initial value
        const mediaWatcher = window.matchMedia("(max-width: 820px)");
        setIsMobileSized(mediaWatcher.matches);

        //watch for updates
        function updateIsNarrowScreen(e) {
            return
        }
        mediaWatcher.addEventListener("change", updateIsNarrowScreen);

        // clean up
        return function cleanup() {
            mediaWatcher.removeEventListener("change", updateIsNarrowScreen);
        };
    });

    return product ? (
        <>
            <Modal className={classes.modalWrapperModal + " forModalBorderRadius"} isOpen={modalToggle} toggle={handleToggleModal} size="lg">
                <div className={classes.modalWrapper}>
                    {
                        !isMobileSizes && (
                            <>
                                <PublicationHeaderModal product={product} toggle={toggle}></PublicationHeaderModal>
                                <div className={classes.bodyWrapper}>
                                    <div className={classes.bodyWrapperLeft}>
                                        <PublicationBodyModalPhotos product={product}></PublicationBodyModalPhotos>
                                        <PublicationBodyModalDescriptions product={product}></PublicationBodyModalDescriptions>
                                    </div>
                                    <div className={classes.bodyWrapperRigth}>
                                        <PublicationBodyModalProductData product={product}></PublicationBodyModalProductData>
                                        <PublicationStatus statusData= {{ status: product?.status, translatedStatus: product.translated_status }} />
                                        <PublicationBodyModalProductHealthOrBuy product={product} />
                                        {
                                            (product.has_buybox || product.has_healthcheck) && (
                                                <PublicationBodyModalProductMaxHealthOrBuy product={product} />
                                            )
                                        }
                                    </div>
                                </div>
                            </>
                        )
                    }

                    {
                        isMobileSizes && (
                            <>
                                <div className={classes.bodyWrapperLeft}>
                                    <PublicationHeaderMobile product={product} toggle={toggle}></PublicationHeaderMobile>
                                    <PublicationBodyModalPhotos product={product}></PublicationBodyModalPhotos>
                                    <PublicationBodyDetailsMobile product={product} ></PublicationBodyDetailsMobile>
                                    <PublicationStatus statusData= {{ status: product?.status, translatedStatus: product.translated_status }} />
                                    <PublicationBodyModalDescriptions product={product}></PublicationBodyModalDescriptions>
                                    {
                                        (product.has_buybox || product.has_healthcheck) && (
                                            <PublicationBodyModalProductMaxHealthOrBuy product={product} />
                                        )
                                    }
                                </div>
                            </>
                        )
                    }
                </div>
            </Modal>
        </>
    ) : null
}

const PublicationHeaderMobile = ({ product, toggle }) => {
    return (
        <div className={classes.mobileHeaderWrapper}>
            <div className={classes.xButtonWrapper} onClick={toggle}>
                <img className={classes.xButton} src={Close} />
            </div>
            <div className={classes.cardTextWrapper}>
                <span className={classes.textName}>{product.name ?? "Sin Información"}</span>
                <span className={classes.textTitle}>{product.category ?? "Sin Información"}</span>
            </div>
            <div style={{ width: "50px", height: "50px", display: "grid", placeContent: "center" }}>
                <img src={product.channel_icon} />
            </div>
        </div>
    )
}

const PublicationBodyDetailsMobile = ({ product }) => {
    return (
        <div className={classes.mobileDetailsWrapper}>
            <div className={classes.cardTextMobileWrapper}>
                <div className={classes.cardTextWrapperPrice}>
                    <span className={classes.textPrice}>$&nbsp;{product.price ?? "Sin Información"}</span>
                    <span className={classes.textCurrency}>&nbsp;{product.currency ?? ""}</span>
                </div>
                <div>
                    <div className={classes.unitWrapper}>
                        <img className={classes.imgIcon} src={Location} />
                        {
                            (product.logistica != null && product.logistica !== "") && (
                                <div>
                                    <Tooltip title={product.logistica} arrow>
                                        <span>{translateLogisticType(product.logistica)}</span>
                                    </Tooltip>
                                </div>
                            )
                        }
                        {
                            (product.logistica == null || product.logistica === "") && (
                                <div>
                                    <span className={classes.noInfo}>Sin&nbsp;Información</span>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div>
                    <div className={classes.unitWrapper}>
                        <img className={classes.imgIcon} src={Canasta} />
                        {
                            (product.stock != null && product.stock !== "") && (
                                <div>
                                    <span>{product.stock} &nbsp; {product.stock !== 1 ? "Unidades" : "Unidad"}</span>
                                </div>
                            )
                        }
                        {
                            (product.stock == null || product.stock === "") && (
                                <div>
                                    <span className={classes.noInfo}>Sin&nbsp;Información</span>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className={classes.cardBYHMobileWrapper}>
                {
                    product.has_healthcheck && !product.has_healthcheck && (
                        <>
                            <div className={classes.titleWrapper}>
                                <span className={classes.textTitleHOB}>Healthcheck</span>
                            </div>
                            <div className={classes.percentageWrapper}>
                                <ProgressBar percentage={(product.healthcheck?.health ?? 0) * 100} />
                            </div>
                        </>
                    )
                }
                {
                    product.has_buybox && (
                        <>
                            <div className={classes.titleWrapper}>
                                <span className={classes.textTitleHOB}>BuyBox</span>
                            </div>
                            <div className={classes.buyBoxImageWrapper}>
                                {
                                    (["winning"].includes(product.buybox.status)) && (
                                        <img className={classes.imgBuyBox} src={Ganando} />
                                    )
                                }
                                {
                                    ( !["winning", "sharing_first_place"].includes(product.buybox.status)) && (
                                        <img className={classes.imgBuyBox} src={Perdiendo} />
                                    )
                                }
                                {
                                    (["sharing_first_place"].includes(product.buybox.status))&& (
                                        <img className={classes.imgBuyBox} src={Empatando} />
                                    )
                                }
                            </div>
                        </>
                    )
                }
                {
                    !product.has_healthcheck && !product.has_buybox && (
                        <>
                            <div className={classes.titleWrapper}>
                                <span className={classes.textTitleHOB}>Healthcheck y Buybox</span>
                            </div>
                            <EmptyPublications calledFrom='publicationModalBuyOrHealth'/>
                        </>
                    )
                }
            </div>
        </div>
    )
}

const PublicationHeaderModal = ({ product, toggle }) => {
    return (
        <div className={classes.headerWrapper}>
            <div style={{ width: "50px", height: "50px", display: "grid", placeContent: "center" }}>
                <img src={product.channel_icon} />
            </div>

            <div>
                <div className={classes.unitWrapper}>
                    <img className={classes.imgIcon} src={Location} />
                    {
                        (product.logistica != null && product.logistica !== "") && (
                            <div>
                                <Tooltip title={product.logistica} arrow>
                                    <span>{translateLogisticType(product.logistica)}</span>
                                </Tooltip>
                            </div>
                        )
                    }
                    {
                        (product.logistica == null || product.logistica === "") && (
                            <div>
                                <span className={classes.noInfo}>Sin&nbsp;Información</span>
                            </div>
                        )
                    }
                </div>
            </div>
            <div>
                <div className={classes.unitWrapper}>
                    <img className={classes.imgIcon} src={Canasta} />
                    {
                        (product.stock != null && product.stock !== "") && (
                            <div>
                                <span>{product.stock} &nbsp; {product.stock !== 1 ? "Unidades" : "Unidad"}</span>
                            </div>
                        )
                    }
                    {
                        (product.stock == null || product.stock === "") && (
                            <div>
                                <span className={classes.noInfo}>Sin&nbsp;Información</span>
                            </div>
                        )
                    }
                </div>
            </div>
            {/* <div>
                <div className={classes.unitWrapper}>
                    <div>
                        <span></span>
                    </div>
                    <img className={classes.imgIconInfo} src={Question} />
                </div>
            </div> */}
            <div className={classes.xButtonWrapper} onClick={toggle}>
                <img className={classes.xButton} src={Close} />
            </div>
        </div>
    )
}

const PublicationBodyModalPhotos = ({ product }) => {
    const [photoSelected, setPhotoSelected] = useState(product.images ? (product.images[0]?.link ?? "") : "")

    //Init
    useEffect(() => {
        let mini1 = document.getElementById("Mini1");
        mini1.style = "box-shadow: 0px 1px 6px rgba(87, 194, 189, 1);";
    }, [])

    const handlePhotoSelected = (photo, id) => {
        let arrayIds = ["Mini1", "Mini2", "Mini3", "Mini4"];
        if (photo !== "") {
            for (const item of arrayIds) {
                if (item === id) {
                    let miniSelected = document.getElementById(item);
                    miniSelected.style = "box-shadow: 0px 1px 6px rgba(87, 194, 189, 1);";
                } else {
                    let miniSelected = document.getElementById(item);
                    miniSelected.style = "box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.17);";
                }
            }
            setPhotoSelected(photo)
        }
    }

    return (
        <div className={classes.bodyPhotoWrapper}>
            <div className={classes.bodyPhotoWrapperMini}>
                <div className={classes.photoMiniWrapper} id='Mini1' onClick={() => { handlePhotoSelected(product.images ? (product.images[0]?.link ?? "") : "", 'Mini1') }}>
                    <img className={classes.photoMini} src={product.images ? (product.images[0]?.link ?? WhiteCase) : WhiteCase}></img>
                </div>
                <div className={classes.photoMiniWrapper} id='Mini2' onClick={() => { handlePhotoSelected(product.images ? (product.images[1]?.link ?? "") : "", 'Mini2') }}>
                    <img className={classes.photoMini} src={product.images ? (product.images[1]?.link ?? WhiteCase) : WhiteCase}></img>
                </div>
                <div className={classes.photoMiniWrapper} id='Mini3' onClick={() => { handlePhotoSelected(product.images ? (product.images[2]?.link ?? "") : "", 'Mini3') }}>
                    <img className={classes.photoMini} src={product.images ? (product.images[2]?.link ?? WhiteCase) : WhiteCase}></img>
                </div>
                <div className={classes.photoMiniWrapper} id='Mini4' onClick={() => { handlePhotoSelected(product.images ? (product.images[3]?.link ?? "") : "", 'Mini4') }}>
                    <img className={classes.photoMini} src={product.images ? (product.images[3]?.link ?? WhiteCase) : WhiteCase}></img>
                </div>
            </div>
            <div className={classes.photoBodySelectedWrapper}>
                <div className={classes.photoSelectedWrapper}>
                    <img className={classes.photoSelected} src={photoSelected}></img>
                </div>
            </div>
        </div>
    )
}

const PublicationBodyModalDescriptions = ({ product }) => {
    return (
      
            <div className={classes.descriptionWrapper}>
                <span className={classes.textTitleDescription}>Descripción de la publicación</span>
                {product?.long_description ?
                    <div dangerouslySetInnerHTML={{__html: product?.long_description}}/>
                :
                    <span className={classes.noDescriptionMessage}>Sin Información</span>   
                }
            </div>
       
    )
}
const PublicationBodyModalProductData = ({ product }) => {
    return (
        <div className={classes.cardTextWrapper}>
            <span className={classes.textId}>SKU: {product.sku ?? "Sin Información"}</span>
            <span className={classes.textId}>EAN: {product.ean ?? "Sin Información"}</span>
            <span className={classes.textTitle}>{product.category ?? "Sin Información"}</span>
            <span className={classes.textName}>{product.name ?? "Sin Información"}</span>
            <div className={classes.cardTextWrapperPrice}>
                <span className={classes.textPrice}>$&nbsp;{product.price ?? "Sin Información"}</span>
                <span className={classes.textCurrency}>&nbsp;{product.currency ?? ""}</span>
            </div>
        </div>
    )
}

const PublicationBodyModalProductHealthOrBuy = ({ product }) => {
    return (
        <div className={classes.healthOrBuyBoxWrapper}>
            {
                product.has_healthcheck && !product.has_buybox && (
                    <>
                        <div className={classes.titleWrapper}>
                            <span className={classes.textTitleHOB}>Healthcheck</span>
                        </div>
                        <div className={classes.percentageWrapper}>
                            <ProgressBar percentage={(product.healthcheck?.health ?? 0) * 100} />
                        </div>
                    </>
                )
            }
            {
                product.has_buybox && (
                    <>
                        <div className={classes.titleWrapper}>
                            <span className={classes.textTitleHOB}>BuyBox</span>
                        </div>
                        <div className={classes.buyBoxImageWrapper}>
                            {
                                (["winning"].includes(product.buybox?.status)) && (
                                    <img className={classes.imgBuyBox} src={Ganando} />
                                )
                            }
                            {
                                (!["winning", "sharing_first_place"].includes(product.buybox.status)) && (
                                    <img className={classes.imgBuyBox} src={Perdiendo} />
                                )
                            }
                            {
                                ["sharing_first_place"].includes(product.buybox.status) && (
                                    <img className={classes.imgBuyBox} src={Empatando} />
                                )
                            }
                        </div>
                    </>
                )
            }
            {
                !product.has_healthcheck && !product.has_buybox && (
                    <>
                        <div className={classes.titleWrapper}>
                            <span className={classes.textTitleHOB}>Healthcheck y Buybox</span>
                        </div>
                        <EmptyPublications calledFrom='publicationModalBuyOrHealth'/>
                    </>
                )
            }
        </div>
    )
}

const PublicationBodyModalProductMaxHealthOrBuy = ({ product }) => {
    return (
        <>  
            {product.has_buybox ?
                 <div className={classes.healthOrBuyBoxMaxWrapper}>
                    <div className={classes.titleWrapper}>
                        <span className={classes.textTitleHOB}>Análisis de la competencia</span>
                    </div>
                    <div className={classes.channelTextWrapper}>
                        <span className={classes.channelText}>{product.channel ?? "Sin Información"} </span>
                    </div>
                    <div className={classes.priceToWinWrapper}>
                        <span className={classes.priceToWinText}>{product.buybox.price_to_win !== null ? ("$" + product.buybox.price_to_win) : "Sin Información de precio"}</span>
                    </div>
                    <div className={classes.imgBuyBoxWrapper}>
                        {
                            (["winning"].includes(product.buybox.status)) && (
                                <img className={classes.imgBuyBox} src={GanandoIlu} />
                            )
                        }
                        {
                            (!["winning", "sharing_first_place"].includes(product.buybox.status)) && (
                                <img className={classes.imgBuyBox} src={PerdiendoIlu} />
                            )
                        }
                        {
                            ["sharing_first_place"].includes(product.buybox.status) && (
                                <img className={classes.imgBuyBox} src={EmpatandoIlu} />
                            )
                        }
                    </div>
                </div>
            :
                null
            }
            {product.has_healthcheck !== null && product.healthcheck?.actions?.length > 0 ?
                <div className={classes.healthOrBuyBoxMaxWrapper}>
                    <div className={classes.titleWrapper}>
                        <span className={classes.textTitleHOB}>Elementos por cumplir</span>
                    </div>
                   
                    <div className={classes.actionsWrapper}>
                        {
                            product.healthcheck.actions.map((e, i) => (
                                <div key={i} className={classes.listWrapper}>
                                    <div className={classes.checkWrapper}>
                                        <img src={noCheck} className={classes.check} />
                                    </div>
                                    <div className={classes.textAttributeWrapper}>
                                        <span className={classes.textAttribute}>{e.value ?? ""}</span>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
               
                </div>

            :
                null
            }
            
        </>
    )
}

export default PublicationModal;