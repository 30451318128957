import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classes from './PublicationCard.module.css';
import ProgressBar from './ProgressBar';
import PublicationModal from './PublicationModal';
import EmptyPublications from './EmptyPublications';
import Checkbox from '../../../components/UI/Checkbox';
import AlertTooltip from './UI/AlertTooltip';

//images
import Canasta from '../../../assets/img/canasta.png';
import Ganando from '../../../assets/img/ganando 2.png';
import Perdiendo from '../../../assets/img/Perdiendo 2.png';
import Empatando from '../../../assets/img/Empatando 2.png';
import Bell from '../../../assets/img/icons/alert-bell.svg';


const PublicationCard = ({ product, checked, onCheckboxChange, deselectedIds, setDeselectedIds, selectAllPagesChecked, alertSByPublication }) => {
    const [modalToggle, setToggle] = useState(false);
    const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isChecked, setIsChecked] = useState(checked);    

    const handleToggleModal = () => {
        setToggle(!modalToggle);
    }

    useEffect(() => {
        const updateDimensions = () => {
            setWindowWidth(window.innerWidth);
        };
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    useEffect(() => {
        if (deselectedIds.includes(product.id_publication)) {           
            setIsChecked(false);
        } else {
            setIsChecked(checked);
        }        
    }, [checked, deselectedIds, product.id_publication]);

    const handleCheckboxChange = (isChecked) => {
        setIsChecked(isChecked);

        if (!isChecked && selectAllPagesChecked) {
            setDeselectedIds(prevIds => [...prevIds, product.id_publication]);
        } else if (isChecked) {
            setDeselectedIds(prevIds => prevIds.filter(id => id !== product.id_publication));
        }

        onCheckboxChange(product.id_publication, product.client_id, isChecked);
    };

    const handleBellClick = (event) => {
        event.stopPropagation();
    };

    // Find the alert for the current product
    const alertsForProduct = alertSByPublication.filter(alert => alert.id_publication === product.id_publication);
    const tooltipContent = `
        <div style="width: 273px; position: relative; font-size: 15px; text-align: left; display: inline-block; color: #57c2bd; font-family: Montserrat;">
            <p style="margin: 0; font-weight: 300; color: #000;">Esta publicación tiene estas alertas:</p>
            <p style="margin: 0; font-weight: 300; color: #000;">&nbsp;</p>
            ${alertsForProduct.map(alert => `<p style="margin: 0; font-weight: 500;">${alert.alert_name}</p>`).join('')}
        </div>
    `;

    return (
        <>
            <div className={`${classes.cardWrapper} ${isChecked ? classes.checkedCard : ''}`} onClick={handleToggleModal}>
                <AlertTooltip tooltipContent={tooltipContent}>
                    <img className={ alertSByPublication.find(publication => publication.id_publication === product.id_publication) ? classes.bellIcon : classes.bellIconInvisible} src={Bell} alt="Alert Bell" onClick={handleBellClick} />
                </AlertTooltip>
                <Checkbox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                />
                <div className={classes.cardImgWrapper}>
                    <img width={windowWidth < 1300 ? '80%' : '100%'} className={classes.cardImg} src={product.images ? (product.images[0]?.link ?? '') : ''} alt={product.name} />
                </div>
                <div className={classes.cardTextWrapper}>
                    <div className={classes.textCategoryWrapper}>
                        <span className={classes.textTitle}>{product.category}</span>
                    </div>
                    <div className={classes.textNameWrapper}>
                        <span className={classes.textName}>{product.name}</span>
                    </div>
                    <div className={classes.textidsWrapper}>
                        <span className={classes.textId}>ID: {product.id_publication}</span>
                        <span className={classes.textId}>EAN: {product.ean}</span>
                    </div>
                </div>
                <div className={classes.cardPercentageWrapper}>
                    {product.has_healthcheck && !product.has_buybox && (
                        <>
                            <div>
                                <span className={classes.titleInfo}>Healthcheck</span>
                            </div>
                            <div className={classes.flexWrapper}>
                                <div className={classes.percentageWrapper}>
                                    <ProgressBar percentage={(product.healthcheck?.health || 0) * 100} />
                                </div>
                            </div>
                        </>
                    )}
                    {product.has_buybox && (
                        <>
                            <div>
                                <span className={classes.titleInfo}>BuyBox</span>
                            </div>
                            <div className={classes.buyboxWrapper}>
                                <div className={classes.percentageWrapper}>
                                    {['winning'].includes(product.buybox.status) && (
                                        <img className={classes.imgBuyBox} src={Ganando} alt="Winning" />
                                    )}
                                    {!['winning', 'sharing_first_place'].includes(product.buybox.status) && (
                                        <img className={classes.imgBuyBox} src={Perdiendo} alt="Losing" />
                                    )}
                                    {['sharing_first_place'].includes(product.buybox.status) && (
                                        <img className={classes.imgBuyBox} src={Empatando} alt="Tying" />
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                    {!product.has_buybox && !product.has_healthcheck && (
                        <>
                            <div>
                                <span className={classes.titleInfo} style={{ textAlign: 'center' }}>Healthcheck y buybox</span>
                            </div>
                            <div className={classes.flexWrapper}>
                                <EmptyPublications calledFrom="publicationCardHealthcheck" />
                            </div>
                        </>
                    )}
                </div>
                <div className={classes.cardInfoWrapper}>
                    <div>
                        <span className={classes.titleInfo}>Información</span>
                    </div>
                    <div>
                        <img src={product.channel_icon} alt="Channel Icon" />
                    </div>
                    <div className={classes.unitWrapper}>
                        <img src={Canasta} alt="Canasta" />
                        <div>
                            <span>{product.stock ?? 0} Unidades</span>
                        </div>
                    </div>
                </div>
            </div>
            {modalToggle && (
                <PublicationModal
                    product={product}
                    modalToggle={modalToggle}
                    handleToggleModal={handleToggleModal}
                    modalPosition={modalPosition}
                />
            )}
        </>
    );
};

export default PublicationCard;
